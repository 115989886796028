import { createRouter,createWebHistory } from 'vue-router';
import Home from '../view/Home.vue'
const routes = [
  {
    path:'/',
    name:'Home',
    component:Home
  },{
    path:'/About',
    name:'About',
    component:()=>import(/* webpackChunkName: "About" */ '../view/About.vue')
  }
]
const router =createRouter({
  history:createWebHistory(),
  routes
})

export default router