<template>
    <div class="container">  
      <div class='loader df aic jcc'>
        <div>
          <h1>Loading</h1>
          <h2 class='loader--text'>0%</h2>
        </div>
      </div>
      <!-- <div class="box" ref="boxRef">  
      </div>   -->
      <div class='lingyi-wrapper'>
      <header class='df aic jcc'>
        <div>
          <h1>精美影集</h1>
          <h2>发布日期：2024年7月</h2>
          <p class="svg_p">
          <img class="svg" src="../assets/down.svg">
          </p>
        </div>
      </header>
      <section class='lingyi-text'>
        <p style="height:200px;"></p>
        <div class='wrapper text'><span class="place-text"></span>祝：廖凌一小朋友生日快乐！
        <p style="height:200px;"></p>
        </div>
      </section>
      <section class='lingyi-gallery'>
        <ul class='wrapper'>
          <li style="width:13800px;"></li>
          <li v-for="(item,index) in photo_arr2" :key="index">
            <img height='700' :src='item.url' :width='item.width'>
          </li>
          <li style="width:4300px;"></li>
        </ul>
      </section>
      <section>
        <div class="photo_list">
          <div v-for="(item,index) in photo_arr1" :key="item.url" data-aos="fade-up" :data-aos-duration="700+100*index">
            <img :src="item.url"  />
            <p>{{item.title}}</p>
          </div>
        </div>
      </section>
      <section class='lingyi-text'>
        <div class='wrapper text '>
          祝：廖凌一小朋友生日快乐！<span class="place-end"></span>
        </div>
      </section>
      <footer class='df aic jcc'>
        <p>
          © 2024 looknd.com <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备20002360号</a>
      </p>
      </footer>
      <div><installPwa msg='安装本站到桌面'></installPwa></div>
      </div>
  </div>
</template>
<script setup>
import {ref,computed,onMounted} from 'vue'
import {useStore} from '../store/index'
import { gsap } from 'gsap';  
import { ScrollTrigger } from 'gsap/ScrollTrigger';  
import installPwa from '../components/install-pwa.vue'
import AOS from 'aos'
import "aos/dist/aos.css";
// import buttonRun from '../components/button-run.vue';
const store = useStore()
const count = computed(()=>store.count)

const photo_arr1 = ref([
  {
    width:'900px',
    url:require('@/assets/22.gif'),
    title:'本期小寿星：廖凌一'
  },
  {
    width:'900px',
    url:require('@/assets/2.jpg'),
    title:'东方明珠'
  },
  {
    width:'900px',
    url:require('@/assets/3.jpg'),
    title:'东方明珠'
  },
  {
    width:'900px',
    url:require('@/assets/6.jpg'),
    title:'东方明珠'
  },
  {
    width:'900px',
    url:require('@/assets/7.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/1.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/4.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/5.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/8.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/9.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/11.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/12.jpg'),
    title:'东方明珠'
  },
  {
    height:'900px',
    url:require('@/assets/14.jpg'),
    title:'杭州一游'
  },
  {
    height:'900px',
    url:require('@/assets/17.jpg'),
    title:'杭州一游'
  },
  {
    width:'900px',
    url:require('@/assets/10.jpg'),
    title:'杭州一游'
  },
  {
    width:'900px',
    url:require('@/assets/15.jpg'),
    title:'杭州一游'
  },
  {
    width:'900px',
    url:require('@/assets/16.jpg'),
    title:'杭州一游'
  },
  {
    width:'900px',
    url:require('@/assets/18.jpg'),
    title:'杭州一游'
  },
  {
    width:'900px',
    url:require('@/assets/20.jpg'),
    title:'东方明珠'
  },
  {
    width:'900px',
    url:require('@/assets/19.jpg'),
    title:'东方明珠'
  },
  {
    width:'900px',
    url:require('@/assets/21.jpg'),
    title:'东方明珠'
  }
])

const photo_arr2 = ref([
  {
    height:'900px',
    url:require('@/assets/1.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/4.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/5.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/8.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/9.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/11.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/12.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/14.jpg')
  },
  {
    height:'900px',
    url:require('@/assets/17.jpg')
  },
  ])

gsap.registerPlugin(ScrollTrigger);  
  
const boxRef = ref(null);  
console.log(count.value)
// const double = computed(()=>store.double)
// function add(){
//   console.log('add')
//   store.increment()
// }

onMounted(()=>{

  // 使用ScrollTrigger创建一个简单的滚动动画  
  gsap.to(boxRef.value, {  
    duration: 1, // 动画时长  
    x: 1000, // 水平移动300px  
    ease: "power1.inOut", // 缓动函数  
    scrollTrigger: {  
      trigger: null, // 触发元素  
      start: "top top", // 动画开始位置  
      end: "+=1500", // 动画结束位置（在触发元素顶部下500px时）  
      scrub: true, // 滚动时动画会平滑过渡  
    },  
  });  

  AOS.init();

  const showDemo = () => {
  document.body.style.overflow = 'auto';
  document.scrollingElement.scrollTo(0, 0);
  gsap.to(document.querySelector('.loader'), { autoAlpha: 0 });
  
  gsap.utils.toArray('section').forEach((section, index) => {
    const w = section.querySelector('.wrapper');
    const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
    gsap.fromTo(w, {  x  }, {
      x: xEnd,
      scrollTrigger: { 
        trigger: section, 
        scrub: 0.5 
      }
    });
  });
}

// updateProgress()
showDemo()

})
</script>
<style lang="less" scoped>

.container {  
  height: 1500px; /* 确保有足够的滚动空间 */  
  position: relative;  
}  

.place-text{
  margin-left:100px;
}

.place-end{
  margin-right:100px;
}
  
.box {  
  height: 200px;  
  background-color: tomato;  
  color: white;  
  display: flex;  
  align-items: center;  
  justify-content: center;  
}  


::selection {
  background: #87cd33;
  color: white;
}

body {
  overflow: hidden;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
}

h1 { font-size: 5rem }
h2 { font-size: 2rem }

img {
  width: 100%;
  height: auto;
  background: #f0f0f0;
}

img.svg{
  width: 50px;
  background: transparent;
}

ul {
  padding-left: 1rem;
  list-style: none;
}

li {
  flex-shrink: 0;
  width: clamp(10vw, 60vw, 900px);
  padding-right: 1rem;
}

header {height: 100vh}
footer {height: 50vh}

:any-link { color: #4e9815; }

.df {display: flex}
.aic {align-items: center}
.jcc {justify-content: center}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  color: white;
}

.lingyi-wrapper {
  overflow-x: hidden;
}

.wrapper {
  display: flex;
}

.lingyi-gallery:not(.last) {
  padding-bottom: 1rem;
}

.lingyi-text{
  transform: skew(-10deg, -10deg);
  & .text {
    font-size: clamp(5rem, 15vw, 16rem);
    line-height: 1;
    font-weight: 900;
  }
}

.svg_p{
  & img{
    animation: svg_run ease-in-out 0.8s infinite;
  }
}

// 照片列表
.photo_list{
  display: flex!important;
  flex-direction: column;
  gap: 10px;
  & > div{
    border-radius: 6px;
    box-shadow: 0 0 10px #ddd;
    padding: 20px;
    img{
      width: 500px;
      height: auto;
      max-height: 900px;
    }
  }
}

@keyframes svg_run {
  0%{
    transform:translateY(0);
  }
  50%{
    transform:translateY(20px);
  }
  100%{
    transform:translateY(0);
  }
}

@media screen and ( max-width:980px) {
  .photo_list{
    & > div{
      img{
        width:100%;
        height: auto;
        max-height: auto;
      }
    }
  }
}
</style>