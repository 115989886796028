<template>
  <div class="pwa">
    <button class="install" @click="installApp" v-if="showInstallButton">
      {{msg}}
    </button>
    <div id="content"></div>
  </div>
</template>

<script setup>
import {ref,defineProps,onMounted,onUnmounted} from 'vue'
const props = defineProps({
  msg:{
    type:String,
    required:true
  }
})
const showInstallButton=ref(false) // 用来判断当前浏览器是否支持安装
const installPromptEvent=ref(null) // 实例对象 
onMounted(()=>{
  window.addEventListener('beforeinstallprompt', handleInstallPrompt);

  console.log('mounted 加载了',installPromptEvent.value,showInstallButton.value)
  // 判断安装环境
  const isInStandaloneMode = () =>window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');
        console.log('panduan env:', isInStandaloneMode())


        if (isInStandaloneMode()) {
          // 在PWA中打开了
            // document.getElementById('content').innerHTML = '在pwa中打开了'
            // window.location.href = 'https://www.baidu.com/'
            // document.body.innerHTML = '是在pwa中打开的'
        } else {
          // 在正常浏览器打开
            // document.getElementById('content').innerHTML =
            //     '在桌面快捷打开会自动跳转到baidu'
            // document.body.innerHTML = '请在pwa中打开'
        }
})

onUnmounted(()=>{
  window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
})

function handleInstallPrompt(event){
  console.log('event')
  // 防止默认的应用安装提示
  // event.preventDefault();
  // 在Vue组件中保存事件对象
  installPromptEvent.value = event;
  // 显示安装按钮
  showInstallButton.value = true;
}




function installApp() {
  console.log('点击安装pwa', installPromptEvent.value)
  if (installPromptEvent.value) {
    // 调用安装操作
    installPromptEvent.value.prompt();
    // 监听安装结果
    installPromptEvent.value.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
            console.log('用户安装了PWA')
        } else {
            console.log('用户拒绝安装PWA')
        }
        // 重置事件
        installPromptEvent.value = null
    })
  }
}
console.log(props.msg)

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
a {
  color: #42b983;
}
button{
  margin-bottom:20px;
}

.install{
  padding: 20px;
  background: #67c23a;
  border: 0;
  font-size: 16px;
  color: white;
  border-radius: 6px;
}
</style>
